<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- name -->
            <AFormItem
                label="Nama"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="name"
                has-feedback>
                <AInput
                    placeholder="nama"
                    :disabled="form.readOnly"
                    v-model:value="form.name" />
            </AFormItem>

            <!-- description -->
            <AFormItem
                label="Deskripsi"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="description"
                has-feedback>
                <ATextarea
                    placeholder="deskripsi"
                    :disabled="form.readOnly"
                    v-model:value="form.description"
                    style="width:100%;"/>
            </AFormItem>

            <!-- tanggal awal -->
            <AFormItem
                label="Tanggal Awal"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="begin_date"
                has-feedback>
                <ADatePicker
                    v-model:value="form.begin_date"
                    :disabled-date="disabledStartDate"
                    placeholder="tanggal awal"
                    style="width:100%;"
                    format="DD MMMM YYYY"
                    value-format="YYYY-MM-DD"/>
            </AFormItem>

            <!-- tanggal akhir -->
            <AFormItem
                label="Tanggal Akhir"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="end_date"
                has-feedback>
                <ADatePicker
                    v-model:value="form.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="tanggal akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"
                    value-format="YYYY-MM-DD"/>
            </AFormItem>

            <!-- active -->
            <AFormItem
                label="Status"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="active"
                has-feedback>
                <ARadioGroup
                    v-model:value="form.active"
                    style="width:100%">
                    <a-radio :value="true">Aktif</a-radio>
                    <a-radio :value="false">Non Aktif</a-radio>
                </ARadioGroup>
            </AFormItem>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>

        <div v-if="form.errors.any()">
            <AAlert
                v-for="(item, index) in form.errors.all()"
                type="error"
                class="mb-2"
                :key="index"
                :message="item.message"
                banner
                closable />
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            name: null,
            description: null,
            begin_date: null,
            end_date: null,
            active: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Master Data Sample',
            endpoint: {
                create: '/api/data-sample',
                update: `/api/data-sample/${props.item.id}`,
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Nama tidak boleh kosong!',
                    },
                ],
                description: [
                    {
                        required: true,
                        message: 'Deskripsi tidak boleh kosong!',
                    },
                ],
                active: [
                    {
                        required: true,
                        message: 'Status tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledStartDate = (current) => {
            return current && current > moment(form.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(form.begin_date) >= current
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint.create)
                    .then(({ data }) => {
                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, active } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.put(state.endpoint.update)
                    .then(({ data }) => {
                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, active } }) => {
                        if (data.error) {
                            message.error(data.error)
                        }

                        if (data.errors) {
                            state.errors = data.errors
                        }
                    })
            }

        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.name = props.item.name
                form.description = props.item.description
                form.begin_date = props.item.begin_date
                form.end_date = props.item.end_date
                form.active = props.item.active

                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Master Data Sample`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledStartDate,
            disabledEndDate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
